import { Component } from "react";
import { Link } from "preact-router";
import throttle from "lodash/throttle";
import style from "./style.module.scss";

import Container from "../../components/container";
import { hasTouchEvents } from "../../utils/browser-features.js";

import NordDDBLogoWhite from "../../assets/logos/nordddb-logo-1.svg";
import NordDDBLogoBlack from "../../assets/logos/nordddb-logo-2.svg";

class SiteNav extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      isOffscreen: false,
      isTransparent: true,
      isMouseNear: false,
      latestScrollPosition: -1,
    };
    this.handleScroll = throttle(this.handleScroll.bind(this), 500);
    this.handleMouseMove = throttle(this.handleMouseMove.bind(this), 500, {
      leading: false,
    });
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.handleScroll);
      if (hasTouchEvents() === false) {
        window.addEventListener("mousemove", this.handleMouseMove);
      }
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.handleScroll);
      if (hasTouchEvents() === false) {
        window.removeEventListener("mousemove", this.handleMouseMove);
      }
    }
  }

  handleScroll() {
    this.setState({
      latestScrollPosition: window.pageYOffset,
      isOffscreen:
        this.state.latestScrollPosition < window.pageYOffset &&
        !this.state.isMouseNear,
      isTransparent: !(window.pageYOffset > 10),
    });
  }

  handleMouseMove(event) {
    this.setState({
      isOffscreen: window.pageYOffset > 10 && event.clientY > 200,
    });
  }

  openMenu() {
    this.setState({
      isOpen: true,
      isOffscreen: false,
    });
  }

  closeMenu() {
    this.setState({
      isOpen: false,
      isOffscreen: false,
    });
  }

  toggleMenu() {
    const toggle = this.state.isOpen ? this.closeMenu : this.openMenu;
    toggle.call(this);
  }

  navToggleChange() {
    this.toggleMenu();
  }

  onLinkClick() {
    this.closeMenu();
  }

  render() {
    const { isOffscreen, isOpen, isTransparent } = this.state;
    const { transparent } = this.props;
    const navBarClasses = [
      style.navBar,
      isOffscreen && !isOpen ? style.navBar__isOffscreen : null,
      (isTransparent && transparent) || isOpen
        ? style.navBar__isTransparent
        : null,
    ].join(" ");
    const whiteLogoClasses = [
      style.siteLink__logo,
      isTransparent && transparent && !isOpen
        ? null
        : style.siteLink__logo__isHidden,
    ].join(" ");
    const blackLogoClasses = [
      style.siteLink__logo,
      isTransparent && transparent && !isOpen
        ? style.siteLink__logo__isHidden
        : null,
    ].join(" ");
    const navIconClasses = [
      style.navIcon,
      isTransparent && transparent && !isOpen ? style.navIcon__isWhite : null,
    ].join(" ");

    return (
      <div className={style.siteNav}>
        <input
          type="checkbox"
          id="site-nav-trigger"
          className={style.navTrigger}
          checked={this.state.isOpen}
          onChange={this.navToggleChange.bind(this)}
        />

        <header className={navBarClasses}>
          <Link
            href="/"
            onClick={this.onLinkClick.bind(this)}
            className={style.siteLink}
          >
            <img src={NordDDBLogoWhite} className={whiteLogoClasses} />
            <img src={NordDDBLogoBlack} className={blackLogoClasses} />
          </Link>

          <label for="site-nav-trigger" className={style.navToggle}>
            <svg width="29.5" height="24" className={navIconClasses}>
              <g>
                <path
                  className={[style.navIcon__path, style.navIcon__bottom].join(
                    " "
                  )}
                  d="M4.5,19h23"
                />
                <path
                  className={[style.navIcon__path, style.navIcon__top].join(
                    " "
                  )}
                  d="M4.5,5h23"
                />
                <path
                  className={[style.navIcon__path, style.navIcon__middle].join(
                    " "
                  )}
                  d="M4.5,12h23"
                />
              </g>
              <g>
                <path
                  className={[
                    style.navIcon__path,
                    style.navIcon__arrowBottom,
                  ].join(" ")}
                  d="M27.5,12L19,22"
                />
                <path
                  className={[
                    style.navIcon__path,
                    style.navIcon__arrowMiddle,
                  ].join(" ")}
                  d="M27.5,12H2"
                />
                <path
                  className={[
                    style.navIcon__path,
                    style.navIcon__arrowTop,
                  ].join(" ")}
                  d="M27.5,12L19,2"
                />
              </g>
              <path
                className={[
                  style.navIcon__path,
                  style.navIcon__middleHover,
                ].join(" ")}
                d="M17.5,12h-15"
              />
            </svg>
          </label>
        </header>

        <nav className={style.navMenu}>
          <div className={style.navMenu__wrapper}>
            <span className={style.navItems}>
              <Container>
                <Link
                  href="/work"
                  onClick={this.onLinkClick.bind(this)}
                  className={style.navItem}
                >
                  <span className={style.navItem__label}>Some of our</span>
                  <br />
                  <span className={style.navItem__title}>work</span>
                </Link>
                <Link
                  href="/about"
                  onClick={this.onLinkClick.bind(this)}
                  className={style.navItem}
                >
                  <span className={style.navItem__label}>About</span>
                  <br />
                  <span className={style.navItem__title}>NORDDDB</span>
                </Link>
                <Link
                  href="/contact"
                  onClick={this.onLinkClick.bind(this)}
                  className={style.navItem}
                >
                  <span className={style.navItem__label}>Get in</span>
                  <br />
                  <span className={style.navItem__title}>touch</span>
                </Link>
              </Container>
            </span>
          </div>
        </nav>
      </div>
    );
  }

  /* // Saved for prosperity.
	componentDidMount () {
		console.log('top:', document.querySelector(`.${style.navIcon__top}`).getTotalLength());
		console.log('middle:', document.querySelector(`.${style.navIcon__middle}`).getTotalLength());
		console.log('middle(hover):', document.querySelector(`.${style.navIcon__middleHover}`).getTotalLength());
		console.log('bottom:', document.querySelector(`.${style.navIcon__bottom}`).getTotalLength());
		console.log('arrowTop:', document.querySelector(`.${style.navIcon__arrowTop}`).getTotalLength());
		console.log('arrowMiddle:', document.querySelector(`.${style.navIcon__arrowMiddle}`).getTotalLength());
		console.log('arrowBottom:', document.querySelector(`.${style.navIcon__arrowBottom}`).getTotalLength());
	}
	*/
}

export default SiteNav;
