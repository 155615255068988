/**
 * Returns if browser supports object-fit
 * @returns {boolean}
 */
export function hasObjectFit () {
	return ('objectFit' in document.documentElement.style !== false);
}

/**
 * Returns if browser supports touch events
 * @returns {boolean}
 */
export function hasTouchEvents () {
	return ('ontouchstart' in document.documentElement !== false);
}

/**
 * Returns if the browser is samsung phone
 * @returns {boolean}
 */
export function isSamsungBrowser () {
	if (navigator.userAgent.match(/SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i)) {
		return true;
	} else {
		return false;
	}
}
