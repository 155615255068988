import { h, Component } from "preact";
import Router from "preact-router";
import Match from "preact-router/match";
import {
  parseHomepage,
  parseHomepageBySlug,
  parseCaseBySlug,
  parseNewsBySlug,
  parseCareerBySlug,
  checkIfHomepageExistsBySlug,
  checkIfCaseExistsBySlug,
  checkIfNewsExistsBySlug,
  checkIfCareerExistsBySlug,
  parseContactPage,
  parseWorkPage,
  parseAboutPage,
} from "../../utils/data-mapper";
import { hasTouchEvents } from "../../utils/browser-features";

import style from "./style.module.scss";

import SiteIntro from "../site-intro";
import SiteNav from "../site-nav";

import HomePage from "../../routes/home";
import CasePage from "../../routes/case";
import NewsPage from "../../routes/news";
import CareerPage from "../../routes/career";
import ContactPage from "../../routes/contact";
import WorkPage from "../../routes/work";
import AboutPage from "../../routes/about";
import NotFoundPage from "../../routes/not-found";

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      siteData: SITE_DATA,
      showSiteIntro: typeof window !== "undefined",
      fadeSiteIntro: false,
      transparentSiteNav: false,
    };
    this.handleRoute = this.handleRoute.bind(this);
    this.handleRouteLoaded = this.handleRouteLoaded.bind(this);
  }

  componentDidMount() {
    document
      .getElementsByTagName("html")[0]
      .setAttribute("class", hasTouchEvents() ? "touch" : "no-touch");
  }

  handleRoute(event) {
    this.currentUrl = event.url;
    this.currentSlug = event.matches ? event.matches.slug : { slug: "/" };

    /*
     * Make the site navigation bar transparent if the current path is homepage or a case page
     **/
    const pathArr = event.url.split("/");
    this.setState({
      transparentSiteNav:
        pathArr[1] === "" ||
        pathArr[1] === "case" ||
        pathArr[1] === "about" ||
        pathArr[2] && pathArr[2].includes("freakfactory"),
    });
  }

  handleRouteLoaded() {
    this.setState({
      fadeSiteIntro: true,
    });
  }

  getNotFoundPage() {
    const { siteData } = this.state;
    return (
      <NotFoundPage
        default
        type="404"
        offices={siteData.offices}
        settings={siteData.settings}
        routeLoaded={this.handleRouteLoaded}
      />
    );
  }

  render() {
    const { siteData, showSiteIntro, fadeSiteIntro, transparentSiteNav } =
      this.state;

    return (
      <div className={style.app}>
        <SiteIntro show={showSiteIntro} fade={fadeSiteIntro} />
        <SiteNav transparent={transparentSiteNav} />
        <Router onChange={this.handleRoute}>
          <Match path="/">
            {() => (
              <HomePage
                homepage={parseHomepage(siteData)}
                offices={siteData.offices}
                settings={siteData.settings}
                assetsDir="assets"
                routeLoaded={this.handleRouteLoaded}
              />
            )}
          </Match>
          <Match path="/case/:slug">
            {() => {
              return checkIfCaseExistsBySlug(
                siteData.cases,
                this.currentSlug
              ) ? (
                <CasePage
                  caseItem={parseCaseBySlug(siteData, this.currentSlug)}
                  offices={siteData.offices}
                  settings={siteData.settings}
                  routeLoaded={this.handleRouteLoaded}
                />
              ) : (
                this.getNotFoundPage()
              );
            }}
          </Match>
          <Match path="/news/:slug">
            {() => {
              return checkIfNewsExistsBySlug(
                siteData.news,
                this.currentSlug
              ) ? (
                <NewsPage
                  newsItem={parseNewsBySlug(siteData, this.currentSlug)}
                  offices={siteData.offices}
                  settings={siteData.settings}
                  routeLoaded={this.handleRouteLoaded}
                />
              ) : (
                this.getNotFoundPage()
              );
            }}
          </Match>
          <Match path="/career/:slug">
            {() => {
              return checkIfCareerExistsBySlug(
                siteData.careers,
                this.currentSlug
              ) ? (
                <CareerPage
                  careerItem={parseCareerBySlug(siteData, this.currentSlug)}
                  offices={siteData.offices}
                  settings={siteData.settings}
                  routeLoaded={this.handleRouteLoaded}
                />
              ) : (
                this.getNotFoundPage()
              );
            }}
          </Match>
          <Match path="/:slug">
            {() => {
              switch (this.currentSlug) {
                case "contact":
                  return (
                    <ContactPage
                      data={parseContactPage(siteData)}
                      routeLoaded={this.handleRouteLoaded}
                    />
                  );

                case "work":
                  return (
                    <WorkPage
                      data={parseWorkPage(siteData)}
                      offices={siteData.offices}
                      settings={siteData.settings}
                      routeLoaded={this.handleRouteLoaded}
                    />
                  );

                case "about":
                  return (
                    <AboutPage
                      data={parseAboutPage(siteData)}
                      offices={siteData.offices}
                      settings={siteData.settings}
                      routeLoaded={this.handleRouteLoaded}
                    />
                  );

                default:
                  return checkIfHomepageExistsBySlug(
                    siteData.subHomepages,
                    this.currentSlug
                  ) ? (
                    <HomePage
                      homepage={parseHomepageBySlug(siteData, this.currentSlug)}
                      offices={siteData.offices}
                      settings={siteData.settings}
                      assetsDir={
                        this.currentUrl.endsWith("/") ? "../assets" : "assets"
                      }
                      routeLoaded={this.handleRouteLoaded}
                    />
                  ) : (
                    this.getNotFoundPage()
                  );
              }
            }}
          </Match>
          {this.getNotFoundPage()}
        </Router>
      </div>
    );
  }
}
