import { h } from 'preact';
import style from './style.module.scss';

export default ({ children, className }) => {
	const containerClass = [
		style.container,
		className
	].join(' ');

	return (
		<div class={ containerClass }>
			{ children }
		</div>
	);
};
