import _ from 'lodash';

/**
 * Finds homepage matching the provided slug
 * @param {Array} homepages
 * @param {string} slug
 * @returns {Object} Matching homepage or empty object
 */
export function getHomepageBySlug (homepages, slug) {
	return _.find(homepages, homepage => homepage.slug === slug) || {};
}

/**
 * Finds case matching the provided id
 * @param {Array} cases
 * @param {string} id
 * @returns {Object} Matching project or empty object
 */
export function getCaseById (cases, id) {
	return _.find(cases, caseItem => caseItem.id === id) || {};
}

/**
 * Finds case matching the provided slug
 * @param {Array} cases
 * @param {string} slug
 * @returns {Object} Matching project or empty object
 */
export function getCaseBySlug (cases, slug) {
	return _.find(cases, caseItem => caseItem.slug === slug) || {};
}

/**
 * Finds office matching the provided id
 * @param {Array} offices 
 * @param {string} id
 * @returns {Object} Matching office or empty object
 */
export function getOfficeById (offices, id) {
	return _.find(offices, office => office.id === id) || {};
}

/**
 * Finds client matching the provided id
 * @param {Array} clients 
 * @param {string} id 
 * @returns {Object} Matching client or empty object
 */
export function getClientById (clients, id) {
	return _.find(clients, client => client.id === id) || {};
}

/**
 * Finds module matching the provided id
 * @param {Array} modules 
 * @param {string} id
 * @returns {Object} Matching client or empty object
 */
export function getModuleById (modules, id) {
	return _.find(modules, module => module.data.id === id);
}

/**
 * Finds modules matching the provided id
 * @param {Array} modules 
 * @param {Array} moduleIds
 * @returns {Object} Matching client or empty object
 */
export function getModulesById (modules, moduleIds) {
	return moduleIds.map(moduleId => {
		return getModuleById(modules, moduleId);
	});
}

/**
 * Finds news matching the provided id
 * @param {Array} news 
 * @param {string} id
 * @returns {Object} Matching client or empty object
 */
export function getNewsById (news, id) {
	return _.find(news, newsItem => newsItem.data.id === id);
}

/**
 * Finds career matching the provided slug
 * @param {Array} careers
 * @param {string} slug
 * @returns {Object} Matching project or empty object
 */
export function getNewsBySlug (news, slug) {
	return _.find(news, newsItem => newsItem.slug === slug) || {};
}

/**
 * Finds career matching the provided slug
 * @param {Array} careers
 * @param {string} slug
 * @returns {Object} Matching project or empty object
 */
export function getCareerBySlug (careers, slug) {
	return _.find(careers, careerItem => careerItem.slug === slug) || {};
}

/**
 * Finds people matching the provided ids
 * @param {Array} people 
 * @param {Array} ids
 * @returns {Object} Matching people
 */
export function getPeopleById (people, ids) {
	return ids.map(id => _.find(people, person => person.id === id));
}

/**
 * Finds people matching the provided office id
 * @param {Array} people 
 * @param {string} id
 * @returns {Object} Matching people or empty object
 */
export function getPeopleByOfficeId (people, id) {
	return _.filter(people, person => person.office === id);
}

/* check methods */

export function checkIfHomepageExistsBySlug (homepages, slug) {
	return _.find(homepages, homepage => homepage.slug === slug) !== undefined;
}

export function checkIfCaseExistsBySlug (cases, slug) {
	return _.find(cases, caseItem => caseItem.slug === slug) !== undefined;
}

export function checkIfCareerExistsBySlug (careers, slug) {
	return _.find(careers, careerItem => careerItem.slug === slug) !== undefined;
}

export function checkIfNewsExistsBySlug (news, slug) {
	return _.find(news, newsItem => newsItem.slug === slug) !== undefined;
}

/* parsers */

/**
 * Parses homepage data
 * @param {Object} siteData 
 * @returns {Object} Homepage parsed data
 */
export function parseHomepage (siteData) {
	return {
		modules: parseModulesById(siteData, siteData.homepage.modules)
	};
}

/**
 * Parses homepage by id
 * @param {Object} siteData 
 * @param {string} slug 
 * @returns {Object} homepage parsed data
 */
export function parseHomepageBySlug (siteData, slug) {
	const homepageData = getHomepageBySlug(siteData.subHomepages, slug);
	return {
		modules: parseModulesById(siteData, homepageData.modules)
	};
}

/**
 * Parses office data by id
 * @param {Object} siteData 
 * @param {string} id 
 * @returns {Object} Office parsed data
 */
export function parseOfficeById (siteData, id) {
	const officeData = getOfficeById(siteData.offices, id);
	return {
		...officeData
	};
}

/**
 * Parses client data by id
 * @param {Object} siteData 
 * @param {string} id 
 * @returns {Object} Client parsed data
 */
export function parseClientById (siteData, id) {
	const clientData = getClientById(siteData.clients, id);
	return {
		...clientData
	};
}

/**
 * Parses case data by id
 * @param {Object} siteData 
 * @param {string} id 
 * @returns {Object} Case parsed data
 */
export function parseCaseBySlug (siteData, id) {
	const caseData = getCaseBySlug(siteData.cases, id);
	return {
		...caseData,
		office: getOfficeById(siteData.offices, caseData.office),
		client: getClientById(siteData.clients, caseData.client),
		modules: parseModulesById(siteData, caseData.modules)
	};
}

/**
 * Parses news data by id
 * @param {Object} siteData 
 * @param {string} id 
 * @returns {Object} Case parsed data
 */
export function parseNewsBySlug (siteData, id) {
	const newsData = getNewsBySlug(siteData.news, id);
	return {
		...newsData,
		office: getOfficeById(siteData.offices, newsData.office),
		modules: getModulesById(siteData.modules, newsData.modules)
	};
}

/**
 * Parses career data by id
 * @param {Object} siteData 
 * @param {string} id 
 * @returns {Object} Case parsed data
 */
export function parseCareerBySlug (siteData, id) {
	const careerData = getCareerBySlug(siteData.careers, id);
	return {
		...careerData,
		office: getOfficeById(siteData.offices, careerData.office),
		modules: getModulesById(siteData.modules, careerData.modules)
	};
}

/**
 * Parses modules data by id
 * @param {Object} siteData 
 * @param {string} id 
 * @returns {Array} Modules parsed data
 */
export function parseModulesById (siteData, modules) {
	if (!modules) return [];

	return modules.map(moduleId => {
		return parseModuleById(siteData, moduleId);
	});
}

/**
 * Parses module data by id
 * @param {Object} siteData 
 * @param {string} id 
 * @returns {Object} Module parsed data
 */
export function parseModuleById (siteData, id) {
	const moduleData = getModuleById(siteData.modules, id);
	if (!moduleData) {
		console.log(`Cannot get module with id ${id}, most likely its a 
		module (in the CMS) that is not supported in the frontend yet`);
		return;
	}

	switch (moduleData.type) {
		case 'big_case_module':
			return parseBigCaseById(siteData, moduleData.data.id);
			break;
		case 'hero_module':
			return parseHeroById(siteData, moduleData.data.id);
			break;
		case 'image_video_module':
			return parseImageVideoById(siteData, moduleData.data.id);
			break;
		case 'image_video_grid_module':
			return parseSingleImageVideoById(siteData, moduleData.data.id);
			break;
		case 'image_video_25_module': case 'image_video_50_module':
			return parseMultipleImageVideoById(siteData, moduleData.data.id);
		case 'quote_module':
			return parseQuoteById(siteData, moduleData.data.id);
			break;
		case 'news_module':
			return parseNews(siteData, moduleData.data.id);
			break;
		case 'careers_module':
			return parseCareers(siteData, moduleData.data.id);
			break;
		case 'content_intro_module':
			return parseContentIntroById(siteData, moduleData.data.id);
			break;
		case 'content_module':
			return parseContentById(siteData, moduleData.data.id);
			break;
		case 'contact_module':
			return parseContactById(siteData, moduleData.data.id);
			break;
	}
}

/**
 * Parses case link data by id
 * @param {Object} siteData 
 * @param {string} id 
 * @returns {Object} Case link parsed data
 */
export function parseBigCaseById (siteData, id) {
	const bigCaseData = getModuleById(siteData.modules, id);
	const caseData = getCaseById(siteData.cases, bigCaseData.data.case);
	return {
		...bigCaseData,
		data: {
			...bigCaseData.data,
			case: {
				...caseData,
				office: getOfficeById(siteData.offices, caseData.office),
				client: getClientById(siteData.clients, caseData.client)
			}
		}
	};
}

/**
 * Parses hero data by id
 * @param {Object} siteData
 * @param {string} id 
 * @returns {Object} Hero parsed data
 */
export function parseHeroById (siteData, id) {
	const heroData = getModuleById(siteData.modules, id);
	return {
		...heroData
	};
}

/**
 * Parses image video data by id
 * @param {Object} siteData
 * @param {string} id 
 * @returns {Object} Image video parsed data
 */
export function parseImageVideoById (siteData, id) {
	const imageVideoData = getModuleById(siteData.modules, id);
	return {
		...imageVideoData
	};
}

/**
 * Parses images videos data by id
 * @param {Object} siteData
 * @param {string} id 
 * @returns {Object} Image video parsed data
 */
export function parseSingleImageVideoById (siteData, id) {
	const imageVideoData = getModuleById(siteData.modules, id);
	const imageVideo = parseImageVideoById(siteData, imageVideoData.data.imageVideo);
	return {
		...imageVideoData,
		data: {
			...imageVideoData.data,
			imageVideo: imageVideo
		}
	};
}

/**
 * Parses images videos data by id
 * @param {Object} siteData
 * @param {string} id 
 * @returns {Object} Image video parsed data
 */
export function parseMultipleImageVideoById (siteData, id) {
	const imagesVideosData = getModuleById(siteData.modules, id);
	const imagesVideos = imagesVideosData.data.imageVideo.map(imageVideo => {
		return parseImageVideoById(siteData, imageVideo);
	});
	return {
		...imagesVideosData,
		data: {
			...imagesVideosData.data,
			imageVideo: imagesVideos
		}
	};
}

/**
 * 
 * @param {Object} siteData 
 * @param {string} id 
 */
export function parseQuoteById (siteData, id) {
	const quoteData = getModuleById(siteData.modules, id);
	return {
		...quoteData
	};
}

export function parseNews (siteData, id) {
	const newsData = getModuleById(siteData.modules, id);
	return {
		...newsData,
		data: {
			...newsData.data,
			office: parseOfficeById(siteData, newsData.data.office),
			items: parseLatestNews(siteData)
		}
	};
}

export function parseLatestNews (siteData) {
	const latestNewsItems = _.sortBy(siteData.news.slice(0, 3), (newsItem) => {
		return new Date(newsItem.date);
	}).reverse();
	const newsData = latestNewsItems.map(newsItem => {
		return {
			...newsItem,
			office: parseOfficeById(siteData, newsItem.office)
		};
	});
	return newsData;
}

export function parseCareers (siteData, id) {
	const careersData = getModuleById(siteData.modules, id);
	return {
		...careersData,
		data: {
			...careersData.data,
			office: parseOfficeById(siteData, careersData.data.office),
			items: parseLatestCareers(siteData)
		}
	};
}

export function parseLatestCareers (siteData) {
	const careersData = siteData.careers.map(careerItem => {
		return {
			...careerItem,
			office: getOfficeById(siteData.offices, careerItem.office)
		};
	});
	return careersData;
}

export function parseContentIntroById (siteData, id) {
	const contentIntroData = getModuleById(siteData.modules, id);
	const officeData = getOfficeById(siteData.offices, contentIntroData.data.office);
	const clientdata = getClientById(siteData.clients, contentIntroData.data.client);
	return {
		...contentIntroData,
		data: {
			...contentIntroData.data,
			office: { ...officeData },
			client: { ...clientdata }
		}
	};
}
export function parseContentById (siteData, id) {
	const contentData = getModuleById(siteData.modules, id);
	return {
		...contentData
	};
}

export function parseContactById (siteData, id) {
	const contentData = getModuleById(siteData.modules, id);
	const offices = contentData.data.offices.map(officeId => {
		let office = getOfficeById(siteData.offices, officeId);
		let people = getPeopleById(siteData.people, office.people);
		return {
			...office,
			people
		};
	});
	return {
		...contentData,
		data: {
			offices
		}
	};
}

/**
 * Parses contact page data
 * @param {Object} siteData 
 * @returns {Object} Homepage parsed data
 */
export function parseContactPage (siteData) {
	return {
		title: siteData.homepage.title,
		description: siteData.homepage.description,
		modules: parseModulesById(siteData, siteData.contact.modules)
	};
}

/**
 * Parses work page data
 * @param {Object} siteData 
 * @returns {Object} Homepage parsed data
 */
export function parseWorkPage (siteData) {
	const parsedCases = siteData.cases.map(caseItem => {
		let caseData = getCaseById(siteData.cases, caseItem.id);
		let office = getOfficeById(siteData.offices, caseItem.office);
		let client = getClientById(siteData.clients, caseItem.client);

		return {
			...caseData,
			office,
			client
		};
	}).filter( x => !x.hideInWorkPage );
	return {
		cases: parsedCases
	};
}

export function parseAboutPage (siteData) {
	return {
		...siteData.aboutPage
	};
}
