import { Component } from 'react';
import style from './style.module.scss';

class SiteIntro extends Component {

	render ({ show, fade }) {
		const siteIntroClasses = [
			style.siteIntro,
			(show) ? 'isVisible' : null,
			(fade) ? 'isFaded' : null
		].join(' ');
		return (
			<div id="site-intro" className={ siteIntroClasses } />
		);
	}

}

export default SiteIntro;
